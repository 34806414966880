import React from 'react'
import { Layout, Stack, Main } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Barra_bassa from '@widgets/Barra_bassa/Barra_bassa'
import ContactForm from '@widgets/ContactForm'
import PageTitle from '@components/PageTitle'

const Privacy = props => {
  const { pageContext: { services = {} } = {} } = props

  return (
    <Layout {...props}>
      <Barra_bassa />

      <Seo title='Privacy' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Privacy'
          subheader=''
        />
      </Stack>

      <Divider />
      <Stack>
        <Main>
          <div>
            <h1>INFORMATIVA PRIVACY RAV Srl</h1>
            <h2>AI SENSI DEGLI ARTT. 13 E 14 DEL REGOLAMENTO EUROPEO 679/2016</h2>
            <h3>Soggetti del Trattamento</h3>
            <p>
              Titolare del trattamento ai sensi delle leggi vigenti, del Regolamento UE
              679/2016 (di seguito “GDPR”) e del D.lgs 196/2003, è Eurorimborso , amministratrice del sito, contattabile tramite la sezione
              “Dati del Titolare”, visionabile in fondo alla presente pagina.
            </p>
            <h3>Legittimazione al trattamento</h3>
            <p>
              Il presente sito tratta i dati prevalentemente in base al consenso degli
              utenti. Il consenso viene rilasciato tramite il banner posto in fondo alla
              pagina, oppure attraverso l’uso o la consultazione del sito, quale
              comportamento concludente.
            </p>
            <p>
              Con l’uso o la consultazione del sito i visitatori e gli utenti approvano
              la presente informativa privacy e acconsentono al trattamento dei loro
              dati personali in relazione alle modalità e alle finalità di seguito
              descritte, compreso l’eventuale diffusione a terzi se necessaria per
              l’erogazione di un servizio.
            </p>
            <p>
              Lo scopo dell’informativa privacy è di fornire la massima trasparenza
              relativamente alle informazioni che il sito raccoglie e come le usa.
            </p>
            <p>
              Il conferimento dei dati e, quindi, il consenso alla raccolta e al
              trattamento degli stessi è facoltativo: l’Utente può negare il consenso, e
              può revocare in qualsiasi momento un consenso già fornito(tramite il
              banner posto a fondo pagina o le impostazioni del browser per i cookie, o
              il link Contatti). Tuttavia, negare il consenso può comportare
              l’impossibilità di erogare alcuni servizi e l’esperienza di navigazione
              nel sito potrebbe risultare compromessa.
            </p>
            <p>
              I dati per la sicurezza del sito e per la prevenzione da abusi e SPAM,
              nonché i dati per l’analisi del traffico del sito (statistica) in forma
              aggregata, sono trattati in base al legittimo interesse del Titolare del
              trattamento alla tutela del sito e degli utenti stessi. In tali casi
              l’Utente ha sempre il diritto di opporsi al trattamento dei dati (vedi
              par. Diritti dell’Utente).
            </p>
            <h3>Finalità del trattamento</h3>
            <h4>Statistica:</h4>
            <p>
              Raccolta di dati e informazioni in forma esclusivamente aggregata e
              anonima al fine di verificare il corretto funzionamento del sito, la
              produttività dello stesso e della Società. Nessuna di queste informazioni
              è correlata alla persona fisica-Utente del sito, e non ne consentono in
              alcun modo l’identificazione, pertanto, non occorre il consenso.
            </p>
            <h4>Sicurezza:</h4>
            <p>
              Raccolta di dati e informazioni al fine di tutelare la sicurezza del sito
              (filtri antispam, firewall, rilevazione virus) e degli utenti e per
              prevenire o smascherare frodi o abusi a danno del sito web e, quindi,
              della Società. I dati sono registrati automaticamente e possono
              eventualmente comprendere anche dati personali (indirizzo Ip) che
              potrebbero essere utilizzati, conformemente alle leggi vigenti in materia,
              al fine di bloccare tentativi di danneggiamento al sito medesimo o di
              recare danno ad altri utenti, o comunque attività dannose o costituenti
              reato. Tali dati non sono mai utilizzati per l’identificazione o la
              profilazione dell’Utente e vengono cancellati periodicamente, pertanto,
              non occorre il consenso.
            </p>
            <h4>Attività accessorie:</h4>
            <p>
              Comunicare i dati a terze parti, nominati Responsabili del trattamento dei
              dati ex art. 28 del GDPR, che svolgono funzioni necessarie o strumentali
              all’operatività del servizio (es. box commenti), e per consentire a terze
              parti di svolgere attività tecniche, logistiche e di altro tipo per nostro
              conto.
            </p>
            <p>
              I fornitori hanno accesso esclusivamente ai dati personali che sono
              necessari per svolgere i propri compiti, e si impegnano a non utilizzare i
              dati per altri scopi, e sono tenuti a trattare i dati personali in
              conformità delle normative vigenti e alle indicazioni impartitegli dalla
              Società in veste di Titolare del trattamento.
            </p>
            <h3>Dati raccolti</h3>
            <h4>Dati raccolti in maniera automatizzata:</h4>
            <p>
              Durante l’utilizzo del sito da parte degli Utenti, possono essere raccolte
              le seguenti informazioni che vengono conservate nei file di log del server
              (hosting) del sito e riguardano il dispositivo non anche dati degli
              interessati:
            </p>
            <ul>
              <li>Indirizzo internet Protocol (IP)</li>
              <li>Tipo di browser</li>
              <li>Parametri del dispositivo usato per connettersi al sito</li>
              <li>Nome dell’internet service provider (ISP)</li>
              <li>Data e orario di visita</li>
              <li>Pagina web di provenienza del visitatore (referral) e di uscita</li>
            </ul>
            <p>
              I dati possono arrivare da terze parti nominati Responsabili del
              trattamento (Google, Service Provider, Boosterbox).
            </p>
            <h4>Dati conferiti volontariamente:</h4>
            <p>
              Nel momento in cui il cliente richiede il servizio e in base al consenso
              dallo stesso implicitamente rilasciato per mezzo di comportamento
              concludente, al fine dell’erogazione del servizio, il sito può raccogliere:
            </p>
            <ul>
              <li>Nome</li>
              <li>Cognome</li>
              <li>Numero di cellulare</li>
              <li>Email</li>
              <li>
                Volo (passato) relativamente al quale l’Utente chiede l’erogazione del
                servizio
              </li>
            </ul>
            <h3>Luogo del trattamento</h3>
            <p>
              I dati vengono trattati, alla fine dell’erogazione del servizio, presso la
              sede del Titolare del Trattamento, e presso
              il datacenter del web Hosting. Il web hosting, è responsabile del trattamento, elaborando i dati per conto della
              Società.
            </p>
            <h3>Periodo di conservazione dei dati</h3>
            <p>
              I dati raccolti dal sito durante il suo funzionamento sono conservati per
              il tempo strettamente necessario a svolgere le attività precisate.
            </p>
            <p>
              Nel caso che, al fine dell’erogazione del servizio si sia provveduto ad
              instaurare un procedimento giudiziale, i dati saranno trattenuti, in virtù
              dell’obbligo di legge imposto al Titolare ed in accordo con i legali
              (Responsabili del trattamento) fiduciari della Società, per un periodo di
              10 anni dalla chiusura del procedimento
            </p>
            <p>Alla scadenza i dati saranno cancellati o anonimizzati.</p>
            <h3>Trasferimento dei dati raccolti a terze parti</h3>
            <p>
              I dati rilevati dal sito generalmente non vengono forniti a terzi, tranne
              che in casi specifici: legittima richiesta da parte dell’Autorità
              giudiziaria e nei soli casi previsti dalla legge, qualora sia necessario
              per la fornitura di uno specifico servizio richiesto dall’Utente; per
              l’esecuzione di controlli di sicurezza o di ottimizzazione del sito.
            </p>
            <h3>Trasferimento dei dati in paesi extra UE</h3>
            <p>
              Il presente sito potrebbe condividere alcuni dei dati raccolti con
              servizi localizzati al di fuori dell’area dell’Unione Europea. In
              particolare con Google, Facebook e Microsoft (LinkedIn) tramite i social
              plugin e il servizio di Google Analytics.
            </p>
            <p>
              Il trasferimento è autorizzato in base a specifiche decisioni dell’Unione
              Europea e del Garante per la tutela dei dati personali, in particolare la
              decisione 1250/2016 (Privacy Shield – qui la pagina informativa del
              Garante italiano
              <a href="https://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/5306161">
                https://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/5306161
              </a>
              ), per cui non occorre ulteriore consenso.
            </p>
            <p>Le aziende sopra menzionate garantiscono la propria adesione al Privacy Shield.</p>
            <h3>Misure di sicurezza</h3>
            <p>
              Trattiamo i dati dei visitatori/utenti in maniera lecita e corretta,
              adottando le opportune misure di sicurezza volte ad impedire accessi non
              autorizzati, divulgazione, modifica o distruzione non autorizzata dei
              dati.
            </p>
            <p>
              Ci impegniamo a tutelare la sicurezza dei tuoi dati personali durante il
              loro invio, utilizzando il protocollo “https”, che cripta le informazioni
              in transito.
            </p>
            <p>
              Il trattamento viene effettuato mediante strumenti informatici e/o
              telematici, con modalità organizzative e con logiche strettamente
              correlate alle finalità indicate.
            </p>
            <p>
              Oltre al Titolare, hanno accesso ai dati, in maniera tracciata, categorie
              di incaricati coinvolti nell’organizzazione del sito ovvero soggetti
              esterni (come fornitori di servizi tecnici terzi, hosting provider), tutti
              regolarmente nominati rispettivamente Incaricati al Trattamento e
              Responsabili esterni del Trattamento.
            </p>
            <h3>Plugin Social Network</h3>
            <p>
              Il presente sito incorpora anche plugin e/o bottoni al fine di consentire
              una facile condivisione dei contenuti sui vostri social network preferiti.
            </p>
            <p>
              Quando visiti una pagina del nostro sito web che contiene un plugin, il
              tuo browser si collega direttamente ai server del social network da dove è
              caricato il plugin, il quale server può tracciare la tua visita al nostro
              sito web e, se del caso, associarla al tuo account del social, in
              particolare se sei connesso al momento della visita o se hai recentemente
              navigato in uno dei siti web contenenti plugin social.
            </p>
            <p>
              Se non desideri che il social network registri i dati relativi alla tua
              visita sul nostro sito web, devi uscire dal tuo account del social e,
              probabilmente, eliminare i cookie che il social network ha installato nel
              tuo browser.
            </p>
            <p>
              La raccolta e l’uso delle informazioni da parte di tali terzi sono
              regolati dalle rispettive informative privacy alle quali si prega di fare
              riferimento.
            </p>
            <ul>
              <li>
                <a href="https://www.facebook.com/policies/cookies/">Facebook</a>
              </li>
              <li>
                <a href="https://help.instagram.com/1896641480634370">Instagram</a>
              </li>
              <li>
                <a href="https://support.google.com/googleplay/answer/32050?co=GENIE.Platform=Android&hl=it">
                  Google Play
                </a>
              </li>
              <li>
                <a href="https://support.apple.com/it-it/HT201265">Apple Store</a>
              </li>
            </ul>
            <h3>Diritti dell’Utente</h3>
            <p>
              Ai sensi del GDPR l’Utente può, secondo le modalità e nei limiti previsti
              dalla vigente normativa, esercitare i seguenti diritti:
            </p>
            <ul>
              <li>
                Opporsi in tutto o in parte, per motivi legittimi, al trattamento dei
                dati personali che lo riguardano ai fini di invio di materiale
                pubblicitario o di vendita diretta o per il compimento di ricerche di
                mercato o di comunicazione commerciale;
              </li>
              <li>Richiedere la conferma dell’esistenza di dati personali che lo riguardano (diritto di accesso);</li>
              <li>Conoscerne l’origine;</li>
              <li>Riceverne comunicazione intelligibile;</li>
              <li>Avere informazioni circa la logica, le modalità e le finalità del trattamento;</li>
              <li>
                Richiederne l’aggiornamento, la rettifica, l’integrazione, la
                cancellazione, la trasformazione in forma anonima, il blocco dei dati
                trattati in violazione di legge, ivi compresi quelli non più necessari
                al perseguimento degli scopi per i quali sono stati raccolti;
              </li>
              <li>
                Nei casi di trattamento basato su consenso, ricevere al solo costo
                dell’eventuale supporto, i suoi dati forniti al titolare, in forma
                strutturata e leggibile da un elaboratore di dati e in un formato
                comunemente usato da un dispositivo elettronico;
              </li>
              <li>
                Il diritto di presentare un reclamo all’Autorità di controllo (Garante
                Privacy –{" "}
                <a href="https://www.garanteprivacy.it/">
                  https://www.garanteprivacy.it/
                </a>
                );
              </li>
              <li>
                Nonché, più in generale, esercitare tutti i diritti che gli sono
                riconosciuti dalle vigenti disposizioni di legge.
              </li>
            </ul>
            <p>Le richieste vanno inoltrate al Titolare ai dati di contatto presenti nella sezione seguente.</p>
            <h3>Dati del Titolare del Trattamento</h3>
            <p>
              Il Titolare del Trattamento è Eurorimborso.
            </p>
          </div>
        </Main>
      </Stack>
      <Stack>
        <Main>
          <ContactForm />
        </Main>
      </Stack>
    </Layout>
  )
}

export default Privacy
